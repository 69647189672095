import { faqCategories } from "../../constants";

export const hero = {
  title: "Frequently asked questions",
  subtext:
    "Answers and advice about finding the right point-of-sale system and integrated software solutions for your business.",
};

export const faqData = {
  // title: "How can we help",
  // description: "Quick answers to use Spoton like a pro.",
  pills: [
    {
      value: faqCategories.POS,
      label: "Point-of-sale basics",
    },
    {
      value: faqCategories.SMALL_BUSINESSES_SOLUTIONS,
      label: "Small business solutions",
    },
    {
      value: faqCategories.ENTERPRISE,
      label: "Enterprise solutions",
    },
    {
      value: faqCategories.PRODUCTS_AND_SERVICES,
      label: "SpotOn products & services",
    },
  ],
  lists: {
    [faqCategories.POS]: [
      {
        title: "What is a point of sale system?",
        body: `A point-of-sale (POS) system is the technology businesses use to process sales. It's the combination of hardware and software that enables businesses to accept payments, create receipts, and keep track of orders. Today, modern point-of-sale systems are cloud-based so that operators can manage their business from anywhere. They also have advanced features like inventory and online order management, data reporting, and customer loyalty programs.`,
      },
      {
        title: "What is a POS system in a restaurant?",
        body: `A restaurant POS system is a point-of-sale system with hospitality features made specifically for restaurants. In addition to order and payment processing, the best POS systems for restaurants are end-to-end solutions that include <a href="https://spoton.com/blog/restaurant-waitlist-app-improve-operations/" target="_blank">digital waitlist management</a>, <a href="https://www.spoton.com/products/reserve/" target="_blank">online reservations</a>, <a href="https://www.spoton.com/products/online-order-and-delivery/" target="_blank">online ordering</a>, <a href="https://spoton.com/blog/handhelds-transform-full-service-restaurants/" target="_blank">handheld POS for servers</a>, <a href="https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/" target="_blank">QR code ordering</a>, <a href="https://www.spoton.com/products/labor-management/" target="_blank">staff scheduling</a>, tip pooling, and <a href="https://www.spoton.com/products/reporting/" target="_blank">system-wide reporting</a>.`,
      },
      {
        title: "What is a point of sale transaction?",
        body: `A point-of-sale (POS) transaction (also known as a point-of-sale purchase transaction) is when a customer buys goods or services from a business, and that purchase is recorded in a point-of-sale system. These transactions can take place in-store or online, and with a POS system, companies can easily track and manage all their transactions from one place.`,
      },
      {
        title: "What is a point of sale terminal?",
        body: `A point-of-sale terminal is the computer hardware that processes point-of-sale transactions at a business's location. These fixed or portable terminals often have touchscreens and are equipped with readers allowing customers to make purchases with credit or debit cards. More advanced POS terminals will also accept mobile payments through digital wallet services like Apple Pay, Google Pay, and NFC (near-field communication).`,
      },
      {
        title: "Why choose SpotOn's point of sale system?",
        body: `Along with 24/7/365 phone support, SpotOn clients enjoy personalized help from representatives who care about the success of your business. SpotOn POS systems offer end-to-end solutions for many types of industries, including <a href="https://www.spoton.com/restaurant-pos/" target="_blank">restaurants</a>, <a target="_blank" href="https://www.spoton.com/retail/">retail</a>, <a href="https://www.spoton.com/automotive/" target="_blank">automotive</a>, <a href="https://www.spoton.com/health-and-beauty/" target="_blank">health & beauty</a>, <a href="https://www.spoton.com/professional-services/" target="_blank">professional services</a>, and even <a href="https://www.spoton.com/enterprise/" target="_blank">enterprise venues</a> like <a href="https://spoton.com/blog/memphis-grizzlies-announce-continued-partnership-spoton/" target="_blank">arenas</a>, <a href="https://spoton.com/blog/major-league-baseball-stadiums-revenue-fan-experience-cloud-based-technology/" target="_blank">stadiums</a>, <a href="https://spoton.com/blog/spoton-expands-footprint-in-education-vertical/" target="_blank">campuses</a>, and <a href="https://www.spoton.com/enterprise/amusements-and-attractions/" target="_blank">attractions</a>. Combined with our transparent pricing and dedication to continuous innovation with 500+ product engineers and developers building better technology, you can trust that we’ll be here to help your business today and tomorrow, no matter what challenges come your way.`,
      },
      {
        title: "What should a good POS system do?",
        body: `A good POS system should do far more than accept payments. It should be a complete end-to-end solution for your entire business. A good POS system should make transactions effortless with multiple payment options for in-store and online transactions, give you remote access to information about your business from anywhere with cloud-based technology, enable you to engage with your customers through marketing and loyalty programs, and deliver real-time reporting that’s relevant to your business. In addition to these features, it should also be able to offer 24/7 customer support and competitive merchant fees without locking you into contracts.`,
      },
      {
        title: "How do I choose a POS system?",
        body: `Along with <a href="https://www.capterra.com/p/197473/SpotOn-Restaurant/reviews/" target="_blank">researching POS companies</a>, the best way to choose the right POS system for your business is to <a href="https://www.spoton.com/demo" target="_blank">talk to a representative</a> who can demonstrate the key features you’re looking for. When deciding who you want to partner with for the long haul, you’ll want to make sure you can get customer support any time and talk to someone in person when you're ready to customize or add more features. And since your POS system will be such an integral part of your operation, it’s also crucial that it can grow with your business as you attract more customers.`,
      },
    ],
    [faqCategories.SMALL_BUSINESSES_SOLUTIONS]: [
      {
        title: "What is the SpotOn Dashboard and what does it include?",
        body: `All small business clients that sign up for SpotOn get access to the <a href="https://login.spoton.com/home/" target="_blank">SpotOn Dashboard</a>, a web-based dashboard you can access anywhere—from your phone or computer—to manage multiple aspects of your business, helping you save time and increase revenue. SpotOn Dashboard tools include point-of-sale transaction <a href="https://www.spoton.com/products/reporting/" target="_blank">reporting and analytics</a>, <a href="https://www.spoton.com/products/review-management/" target="_blank">review management</a>, <a href="https://www.spoton.com/products/marketing/" target="_blank">email and social media marketing</a>, and a <a href="https://www.spoton.com/products/virtual-terminal/" target="_blank">virtual terminal</a> for running online payments. You can also access additional SpotOn solutions through the Dashboard, including the <a href="https://www.spoton.com/retail/" target="_blank">SpotOn Retail</a> product catalog, <a href="https://www.spoton.com/products/website/" target="_blank">SpotOn Website</a>, and <a href="https://www.spoton.com/products/appointments/" target="_blank">SpotOn Appointments</a>.`,
      },
      {
        title: "Does SpotOn have a loyalty rewards program?",
        body: `Yes. <a href="https://www.spoton.com/products/loyalty/" target="_blank">SpotOn Loyalty</a> is a flexible loyalty rewards program that helps you turn first-time customers into long-term regulars. Your guests can earn Spots (points) towards any custom reward you create. Spots can be acquired by visiting your business, purchasing an item, or liking your social media page. And through our <a href="https://spoton.com/blog/the-spoton-app/" target="_blank">consumer mobile app</a> (iPhone and Android), your customers can collect, keep track of, and redeem Spots whenever they visit your business in person or online.`,
      },
      {
        title: "Does SpotOn provide website services?",
        body: `Yes. Through <a href="https://www.spoton.com/products/website/" target="_blank">SpotOn Website</a>, you can get an SEO-optimized mobile-responsive website in as little as 48 hours and ongoing support to keep the site updated. You'll also get web hosting, a custom domain name, and a custom setup to sell online with <a href="https://www.spoton.com/products/online-order-and-delivery/" target="_blank">SpotOn Order</a> (restaurant online & mobile ordering), <a href="https://www.spoton.com/products/e-commerce/" target="_blank">SpotOn Ecommerce</a> (retail), SpotOn Appointments, and <a href="https://www.spoton.com/products/reserve/" target="_blank">SpotOn Reserve</a> (reservations and waitlist).`,
      },
      {
        title:
          "Does SpotOn offer online ordering and delivery for restaurants?",
        body: `Yes. With <a href="https://www.spoton.com/products/online-order-and-delivery/" target="_blank">SpotOn Order</a>, you can avoid commission-based fees from third-party apps by enabling your guests to order takeout directly from your website, with optional local delivery through our flat-fee <a href="https://spoton.com/blog/5-reasons-to-use-online-ordering-with-spoton-delivery-for-your-restaurant/" target="_blank">DoorDash integration</a>. You'll also get integration with Google, order pacing, e-gift cards, text messaging, and customer accounts.`,
      },
      {
        title: "Does SpotOn have QR codes for restaurants?",
        body: `Yes, SpotOn QR codes are included in <a href="https://www.spoton.com/products/online-order-and-delivery/" target="_blank">SpotOn Order</a>. You can place <a href="https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/" target="_blank">QR codes</a> on tables, doors, signs, packages, or anywhere in your restaurant. Guests can view, order, and pay from their phones without waiting in long lines, helping you serve more guests with fewer servers.`,
      },
      {
        title: "Does SpotOn have software for food trucks?",
        body: `Yes. If you've got a food truck, then <a href="https://www.spoton.com/food-truck-pos/" target="_blank">SpotOn Sidekick</a> is just for you. It's a portable handheld POS that allows you to take orders and payments from anywhere on the road. It features built-in cellular network capabilities, multiple payment options, receipt printing from the handheld, Bluetooth printing, online ordering functionality, and the ability to capture marketing data.`,
      },
      {
        title: "Does SpotOn offer a labor-management solution?",
        body: `Yes. <a href="https://www.spoton.com/products/labor-management/" target="_blank">SpotOn Dolce</a> is designed to help you stay on top of labor costs and streamline multiple aspects of labor management. It includes staff scheduling, payroll services, automatic tip pooling, overtime alerts, and other labor law compliance tools. Clients like <a href="https://spoton.com/blog/author/michael/" target="_blank">Michael Mina</a> have said that SpotOn Dolce cuts down their end-of-the-night closeout time from one hour to ten minutes.`,
      },
      {
        title: "What retail point-of-sale solutions does SpotOn offer?",
        body: `SpotOn offers flexible point-of-sale options for <a href="https://www.spoton.com/retail/" target="_blank">retail</a>, <a href="https://www.spoton.com/automotive/" target="_blank">automotive</a>, <a href="https://www.spoton.com/health-and-beauty/" target="_blank">health & beauty</a>, and <a href="https://www.spoton.com/professional-services/" target="_blank">professional service</a> businesses. Whether you want to sell in-store with a straightforward payment terminal, sell your products online, or do all of the above with a product catalog that can easily be published to your online store and in-store POS, SpotOn has you covered. <a href="https://www.spoton.com/retail/demo" target="_blank">Talk to our team</a> to explore your options and pick the solution that best fits your needs.`,
      },
    ],
    [faqCategories.ENTERPRISE]: [
      {
        title: "What type of enterprise businesses does SpotOn help?",
        body: `SpotOn works with all types of high-volume businesses to streamline operations, create better guest experiences, and increase revenue, including <a href="https://www.spoton.com/enterprise/sports-and-entertainment/" target="_blank">sports & entertainment</a>, <a href="https://www.spoton.com/enterprise/" target="_blank">multi-location restaurants</a> and <a href="https://www.spoton.com/enterprise/enterprise-retail/" target="_blank">retail businesses</a>, <a href="https://www.spoton.com/enterprise/education-campuses/" target="_blank">education campuses<a/>, <a href="https://www.spoton.com/enterprise/amusements-and-attractions/" target="_blank">amusements & attractions</a>, <a href="https://www.spoton.com/enterprise/professional-facilities/" target="_blank">professional facilities</a>, and more.`,
      },
      {
        title: "What type of enterprise solutions does SpotOn offer?",
        body: `SpotOn offers a full suite of omnichannel commerce solutions, including <a href="https://www.spoton.com/enterprise/pos-terminals/" target="_blank">point-of-sale</a>, <a href="https://www.spoton.com/enterprise/kiosks/" target="_blank">self-service kiosks</a>, <a href="https://www.spoton.com/enterprise/mobile-and-online-ordering/" target="_blank">mobile & online ordering</a>, <a href="https://www.spoton.com/enterprise/handhelds/" target="_blank">handhelds</a>, <a href="https://www.spoton.com/enterprise/kitchen-displays/" target="_blank">KDS</a>, <a href="https://www.spoton.com/enterprise/back-of-house-management/" target="_blank">back-of-house management</a>, and full-scale enterprise reporting.`,
      },
      {
        title: "What enterprise brands use SpotOn?",
        body: `SpotOn powers the world’s top enterprise brands, including <a href="https://spoton.com/blog/major-league-baseball-stadiums-revenue-fan-experience-cloud-based-technology/" target="_blank">56% of all major league baseball stadiums</a> and 63% of all major league stadiums, as well as the Javits Center, Live Nation, and college sports and campus dining facilities at major universities such as LSU, ASU, University of South Carolina, University of Utah, Gonzaga University, and University of Arizona. `,
      },
    ],
    [faqCategories.PRODUCTS_AND_SERVICES]: [
      {
        title: "What types of businesses does SpotOn work with?",
        body: `SpotOn’s innovative point-of-sale technology powers businesses big and small. From local mom-and-pop restaurants to Major League Baseball and football stadiums across the country, our point-of-sale systems and software solutions can help your business succeed no matter the size.
        <ul>
          <li><a href="https://www.spoton.com/restaurant-pos/" target="_blank">Restaurants</a></li>
          <li><a href="https://www.spoton.com/retail/" target="_blank">Retail shops</a></li>
          <li><a href="https://www.spoton.com/automotive/" target="_blank">Auto shops</a></li>
          <li><a href="https://www.spoton.com/health-and-beauty/hair-salons/" target="_blank">Hair salons</a></li>
          <li><a href="https://www.spoton.com/health-and-beauty/nail-salons/" target="_blank">Nail salons</a></li>
          <li><a href="https://www.spoton.com/professional-services/" target="_blank">Professional services</a></li>
          <li><a href="https://www.spoton.com/enterprise/sports-and-entertainment/" target="_blank">Sports stadiums</a></li>
          <li><a href="https://www.spoton.com/enterprise/sports-and-entertainment/" target="_blank">Entertainment venues</a></li>
          <li><a href="https://www.spoton.com/enterprise/education-campuses/" target="_blank">College campuses</a></li>
        <ul>
        `,
      },
      {
        title: "What type of products and services does SpotOn offer?",
        body: `SpotOn offers a wide range of software and payment technology designed to help you run your business more efficiently and improve your bottom line. From point-of-sale technology and contactless payment options like <a href="https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/" target="_blank">QR codes</a> to <a href="https://www.spoton.com/products/marketing/" target="_blank">marketing</a>, <a href="https://www.spoton.com/products/review-management/" target="_blank">review management</a>, and <a href="https://www.spoton.com/products/reporting/" target="_blank">reporting</a>, SpotOn has what you need to manage and grow your business.`,
      },
      {
        title: "Does SpotOn offer 24/7 customer support?",
        body: `Absolutely. In fact, our average support call wait time is one of the lowest in the industry. We understand how frustrating it can be to wait on hold when you need a quick fix, which is why we have a dedicated team of customer support specialists ready to help you with any questions or issues that come up.
        <br />
        Need help? Contact our team at <a href="tel:+18778144102">(877) 814-4102</a> or by email at <a href="mailto:support@spoton.com">support@spoton.com</a>.`,
      },
      {
        title:
          "I prefer to work with people who are local. Do you provide local service in my area?",
        body: `Unlike most of our competitors, SpotOn has employees in communities across the country.  This includes local Account Executives as well as installation experts for point-of-sale systems.`,
      },
      {
        title: "Why choose SpotOn?",
        body: `We believe business is personal. You built your business on hard work, integrity, and treating customers with respect, and you deserve a partner who shares those values. With SpotOn on your side, you get the tools and support you need to streamline operations, improve the customer experience, and increase profit. Whether you run a cafe, clothing boutique, or a concert venue, we've got you covered.
 
 <br />
 Read more about our why <a href="https://www.spoton.com/company/why-spoton/" target="_blank">here</a>.`,
      },
      {
        title: "Does SpotOn provide payment processing services?",
        body: `Yes. We know how much of a hassle it is to deal with a half-dozen different providers when it comes to things like your POS, payment processing, reporting, and marketing tools. That’s why we’ve created a flexible, end-to-end suite of tools and services that work together—from one trusted partner. We offer affordable, transparent <a href="https://www.spoton.com/products/payments/" target="_blank">payment processing rates</a> with cost-saving options like cash discounting and standard next-day funding.`,
      },
      {
        title: "Does SpotOn provide small business loans?",
        body: `Yes. Qualified SpotOn clients can receive funding quickly and easily through <a href="https://www.spoton.com/products/capital/" target="_blank">SpotOn Capital</a>. We know how difficult it can be to secure traditional small business lending and <a href="https://spoton.com/blog/spoton-capital-offers-restaurants-a-funding-option/" target="_blank">government funds from the SBA</a>, so we’ve made SpotOn Capital easy to use—perfect for growing your business your way.`,
      },
    ],
  },
};

export const footer = {
  title: "Still have questions",
  titleSymbol: "?",
  subtext: "Can’t find the answer you’re looking for?",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Visit our help center",
    target: "https://help.spoton.com/",
  },
};
