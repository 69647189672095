import React, { useState } from "react";
import { string } from "prop-types";
import { faqData } from "../../data/faq";
import { faqCategories } from "../../constants";
import Pill from "../../components-v2/Base/Pill";
import Faqs from "../../components/FAQs/Faqs";

const Faq = ({ className, defaultCategory }) => {
  const [category, setCategory] = useState(defaultCategory);
  return (
    <section className={`${className} px-4 max-w-1440 w-full`}>
      <div className="lg:text-center flex flex-row flex-wrap gap-x-2 justify-center">
        {faqData.pills.map((item) => (
          <Pill
            key={item.value}
            {...item}
            active={item.value === category}
            onClick={setCategory}
          />
        ))}
      </div>

      <Faqs
        className={
          category !== faqCategories.POS ? "hidden" : "max-w-[735px] mx-auto"
        }
        sectionData={{ list: faqData.lists[faqCategories.POS] }}
      />

      <Faqs
        className={
          category !== faqCategories.SMALL_BUSINESSES_SOLUTIONS
            ? "hidden"
            : "max-w-[735px] mx-auto"
        }
        sectionData={{
          list: faqData.lists[faqCategories.SMALL_BUSINESSES_SOLUTIONS],
        }}
      />

      <Faqs
        className={
          category !== faqCategories.ENTERPRISE
            ? "hidden"
            : "max-w-[735px] mx-auto"
        }
        sectionData={{ list: faqData.lists[faqCategories.ENTERPRISE] }}
      />

      <Faqs
        className={
          category !== faqCategories.PRODUCTS_AND_SERVICES
            ? "hidden"
            : "max-w-[735px] mx-auto"
        }
        sectionData={{
          list: faqData.lists[faqCategories.PRODUCTS_AND_SERVICES],
        }}
      />
    </section>
  );
};

Faq.propTypes = {
  className: string,
  defaultCategory: string,
};

Faq.defaultProps = {
  className: "",
  defaultCategory: faqCategories.POS,
};

export default Faq;
