import React from "react";
import { string } from "prop-types";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import FaqSection from "./faq";

import ogImage from "../../images/global_assets/og-image.png";
import { hero, footer } from "../../data/faq";
import { faqCategories } from "../../constants";
import { schemaFAQ } from "../../data/schemaOrg/schema";

import "./styles.css";

const SimpleHero = loadable(() =>
  import("../../components-v2/Sections/Hero/SimpleHero")
);

const FooterHero = loadable(() =>
  import("../../components-v2/Sections/Hero/FooterHero")
);
const Faq = ({ defaultCategory }) => {
  return (
    <Layout className="non-fixed-width faq-page">
      <SEO
        title="SpotOn FAQ | Point of Sale Systems for Your Business"
        description="Get answers to the most frequently asked questions about choosing the right POS system and software for your business."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/faq"
        productSchemaSR
        customSchema={schemaFAQ}
      />

      <div className="mt-48" />

      <SimpleHero className="faq-hero" {...hero} />

      <FaqSection className="relative" defaultCategory={defaultCategory} />

      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        variant={2}
        titleBlueSymbol="?"
        {...footer}
      />
    </Layout>
  );
};

Faq.propTypes = {
  defaultCategory: string,
};

Faq.defaultProps = {
  defaultCategory: faqCategories.POS,
};

export default Faq;
